export type TypeRule =
  | "undefined"
  | "object"
  | "boolean"
  | "number"
  | "bigint"
  | "string"
  | "symbol"
  | "function"
  | { new (...args: unknown[]): unknown };

type CustomRule = (value: unknown) => [boolean, string?, string?];

type TypeRuleDefinition = {
  mode: "type";
  checks: TypeRule[];
};

type LiteralRuleDefinition = {
  mode: "literal";
  checks: unknown[];
};

type CustomRuleDefinition = {
  mode: "custom";
  checks: CustomRule[];
};

type RuleDefinition =
  | TypeRuleDefinition
  | LiteralRuleDefinition
  | CustomRuleDefinition;

type RuleArgument = TypeRule | RuleDefinition | (TypeRule | RuleDefinition)[];

type Decorator = (
  target: unknown,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => void;

export const type = (...checks: TypeRule[]): TypeRuleDefinition => ({
  mode: "type",
  checks,
});

export const literal = (...checks: unknown[]): LiteralRuleDefinition => ({
  mode: "literal",
  checks,
});

export const custom = (...checks: CustomRule[]): CustomRuleDefinition => ({
  mode: "custom",
  checks,
});

export const nonEmptyString: CustomRuleDefinition = custom((value) => [
  typeof value === "string" && value.length > 0,
  "a non-empty string",
]);

export const nonNegativeInteger: CustomRuleDefinition = custom((value) => [
  typeof value === "number" && Number.isInteger(value) && value >= 0,
  "a non-negative integer",
]);

export const pureObject: CustomRuleDefinition = custom((value) => [
  typeof value === "object" && value !== null && !Array.isArray(value),
  "a pure object (non-null and non-array)",
]);

export const objectSchema = (
  name: string,
  schema: Record<string, RuleArgument>
): CustomRuleDefinition =>
  custom((object) => {
    if (
      typeof object !== "object" ||
      object === null ||
      Array.isArray(object)
    ) {
      return [false, `valid ${name} (should be a pure object)`];
    }

    for (const [key, rules] of Object.entries(schema)) {
      const [isValid, received, expected] = validateValue(
        convertRuleArgument(rules),
        (object as Record<string, unknown>)[key]
      );

      if (!isValid) {
        return [
          false,
          `valid ${name} (key "${key}" should be ${expected})`,
          `malformed ${name} (key "${key}" is ${received})`,
        ];
      }
    }

    return [true];
  });

export const runtimeTypeValidation = (
  rules: RuleDefinition[][],
  values: unknown[]
): void => {
  if (values.length > rules.length) {
    throw new Error(
      `Expected at most ${rules.length} argument(s), but got ${values.length}`
    );
  }

  while (values.length < rules.length) {
    values.push(undefined);
  }

  for (const [index, value] of values.entries()) {
    const [isValid, received, expected, delimeter] = validateValue(
      rules[index],
      value
    );

    if (isValid) {
      continue;
    }

    const argumentIndex = index + 1;

    throw new Error(
      `Argument ${argumentIndex} is expected to be ${expected}${delimeter} but got ${received}`
    );
  }
};

export const stringifyReceivedType = (value: unknown): string => {
  let receivedType;
  const types = ["undefined", "boolean", "number", "bigint", "string"];

  if (types.includes(typeof value)) {
    receivedType = typeof value === "string" ? `"${value}"` : `${value}`;
  }

  if (typeof value === "object" && value?.constructor?.name !== "Object") {
    receivedType =
      value === null ? "null" : `instance of ${value?.constructor?.name}`;
  }

  if (!receivedType) {
    receivedType = typeof value;
  }

  return receivedType;
};

export const validateTypes = (...args: RuleArgument[]): Decorator => {
  const finalRuleSet = convertRuleArguments(args);

  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    if (typeof descriptor.value !== "function") {
      throw new Error(
        "The validateTypes decorator can only be applied to methods"
      );
    }

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      runtimeTypeValidation(finalRuleSet, args);
      return originalMethod.apply(this, args);
    };
  };
};

export const validateTypesAsync = (...args: RuleArgument[]): Decorator => {
  const finalRuleSet = convertRuleArguments(args);

  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    if (typeof descriptor.value !== "function") {
      throw new Error(
        "The validateTypesAsync decorator can only be applied to methods"
      );
    }

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      try {
        runtimeTypeValidation(finalRuleSet, args);
      } catch (e) {
        return Promise.reject<Error>(e);
      }

      return originalMethod.apply(this, args);
    };
  };
};

const convertRuleArguments = (args: RuleArgument[]): RuleDefinition[][] => {
  const finalRuleDefinitionSet: RuleDefinition[][] = [];

  for (const arg of args) {
    finalRuleDefinitionSet.push(convertRuleArgument(arg));
  }

  return finalRuleDefinitionSet;
};

const convertRuleArgument = (arg: RuleArgument): RuleDefinition[] => {
  const finalArgumentRuleDefinitions: RuleDefinition[] = [];
  const declaredRules = Array.isArray(arg) ? arg : [arg];

  for (const rule of declaredRules) {
    if (typeof rule === "string" || typeof rule === "function") {
      finalArgumentRuleDefinitions.push(type(rule));
      continue;
    }

    finalArgumentRuleDefinitions.push(rule);
  }

  return finalArgumentRuleDefinitions;
};

const validateValue = (
  ruleDefinitions: RuleDefinition[],
  value: unknown
): [true] | [false, string, string, string] => {
  const expectedTypes: string[] = [];
  let customReceivedType: string | undefined;
  let isValid = false;

  for (const definition of ruleDefinitions) {
    switch (definition.mode) {
      case "type":
        for (const type of definition.checks) {
          if (typeof type === "string") {
            isValid = isValid || typeof value === type;
            expectedTypes.push(`of type ${type}`);

            continue;
          }

          isValid = isValid || value instanceof type;
          expectedTypes.push(`an instance of ${type.name}`);
        }

        break;
      case "literal":
        for (const type of definition.checks) {
          isValid = isValid || value === type;
          expectedTypes.push(
            typeof type === "string" ? `"${type}"` : `${type}`
          );
        }

        break;
      case "custom":
        for (const check of definition.checks) {
          const [checkPassed, typeDescription, receivedType] = check(value);
          isValid = isValid || checkPassed;

          if (!customReceivedType && receivedType) {
            customReceivedType = receivedType;
          }

          if (typeDescription) {
            expectedTypes.push(typeDescription);
          }
        }

        break;
    }
  }

  if (isValid) {
    return [true];
  }

  const receivedType = customReceivedType || stringifyReceivedType(value);
  const lastIndex = expectedTypes.length - 1;
  const expectedTypesString =
    lastIndex > 0
      ? `${expectedTypes.slice(0, lastIndex).join(", ")} or ${
          expectedTypes[lastIndex]
        }`
      : expectedTypes.join(", ");

  return [false, receivedType, expectedTypesString, lastIndex > 1 ? ";" : ","];
};
